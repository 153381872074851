import { ThemeMode } from 'theme-v2/provider/index';
import { sizeProps, themeModeProps } from './constant';
import { InputProps } from './types';
import styled, { css } from 'styled-components';
import Paragraph from '../../atoms/paragraph';
import Flexbox from '../../atoms/flexbox';
import themeGet from '../../../utils/theme-get';
import cleanComponentProps from '../../../utils/cleanComponentProps';

export const StyledLabel = styled(Paragraph)<{
  isRequired: boolean;
}>`
  font-weight: ${themeGet('fontWeights.regular')};
  ${props =>
    props.isRequired &&
    `&::after { 
      content: '*';
      color: ${themeGet('colors.text.light.error')(props)};
    `}
`;

export const StyledPlaceHolder = styled(Paragraph)``;

export const StyledAddonContainer = cleanComponentProps(Flexbox, [
  'inputSize',
  'themeMode'
])<{
  inputSize: InputProps['size'];
  themeMode: ThemeMode;
}>`
  height: ${props => themeGet(sizeProps[props.inputSize].height)(props)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid ${themeGet('borders.1')}px
    ${props => themeGet(themeModeProps[props.themeMode].borderColor)(props)};
`;

export const StyledHorizontalLine = cleanComponentProps(Flexbox, [
  'themeMode',
  'inputSize'
])<{
  themeMode: ThemeMode;
  inputSize: InputProps['size'];
}>`
  height: 1px;
  width: calc(
    100% + 2 * ${props => themeGet(sizeProps[props.inputSize].padding)(props)}px
  );
  background-color: ${props =>
    themeGet(themeModeProps[props.themeMode].borderColor)(props)};
  margin: 0 -${props =>
    themeGet(sizeProps[props.inputSize].padding)(props)}px; ;
`;

export const StyledInputWrapper = cleanComponentProps(Flexbox, [
  'inputSize',
  'disabled',
  'label',
  'themeMode',
  'inputType',
  'transparent'
])<{
  inputSize: InputProps['size'];
  disabled: InputProps['disabled'];
  label?: InputProps['label'];
  themeMode: ThemeMode;
  inputType: InputProps['type'];
  transparent: InputProps['transparent'];
}>`
  flex-direction: column;
  position: relative;

  ${StyledLabel} {
    ${props =>
      props.inputSize !== 'small'
        ? css`
            position: absolute;
            background-color: ${themeGet(
              props.themeMode === 'dark' ? 'colors.black' : 'colors.white'
            )};
            padding: 0 ${themeGet('space.1')}px;
            margin-left: ${themeGet(sizeProps[props.inputSize].padding)}px;
            margin-top: -${themeGet('space.2')}px;
          `
        : ''}
  }
  .rc-input-affix-wrapper {
    height: ${props => themeGet(sizeProps[props.inputSize].height)(props)}px;
    box-sizing: border-box;
    display: inline-flex;
    margin-top: ${props =>
      themeGet(`space.${props.inputSize !== 'small' && props.label ? 2 : 0}`)(
        props
      )}px;
    padding: 0 ${props =>
      themeGet(sizeProps[props.inputSize].padding)(props)}px;
    align-items: center;
    border-radius: ${props =>
      themeGet(sizeProps[props.inputSize].borderRadius)(props)}px;
    border: ${props =>
      props.inputType !== 'search' && !props.transparent
        ? `solid ${themeGet('borders.1')(props)}px ${themeGet(
            themeModeProps[props.themeMode].borderColor
          )(props)}`
        : 'none'};
    background-color: ${props =>
      props.inputType === 'search'
        ? themeGet(themeModeProps[props.themeMode].searchBgColor)
        : 'transparent'};

    & input,
    & span.rc-input {
      width: 100%;
      font-size: ${props =>
        themeGet(sizeProps[props.inputSize].fontSize)(props)}px;
      line-height: ${props =>
        themeGet(sizeProps[props.inputSize].lineHeight)(props)}px;
      color: ${props =>
        themeGet(
          `colors.text.${props.themeMode}.${props.disabled ? 'text3' : 'text1'}`
        )(props)};
    }

    &:not(.rc-input-affix-wrapper-disabled):hover {
      ${props =>
        props.inputType === 'search'
          ? `
          background-color: ${themeGet(
            themeModeProps[props.themeMode].searchHoverBgColor
          )(props)};
        `
          : props.transparent
          ? `border: none;`
          : `
        border: solid ${themeGet('borders.1')(props)}px
          ${themeGet(themeModeProps[props.themeMode].hoverBorderColor)(props)};
        `}
    }

    &.input-focused,
    &.input-focused:hover {
      ${props =>
        props.inputType === 'search'
          ? `
          background-color: ${themeGet(
            themeModeProps[props.themeMode].searchHoverBgColor
          )(props)};
        `
          : props.transparent
          ? `border: none;`
          : `
        border: solid ${themeGet('borders.1')(props)}px
          ${themeGet(themeModeProps[props.themeMode].focusedBorderColor)(
            props
          )};
        `}
    }
  }
  .input-error {
    &.rc-input-affix-wrapper.rc-input-affix-wrapper:not(.rc-input-affix-wrapper-disabled), & .rc-input-affix-wrapper.rc-input-affix-wrapper:not(.rc-input-affix-wrapper-disabled) {
      border: solid ${themeGet('borders.1')}px
        ${props =>
          themeGet(themeModeProps[props.themeMode].errorBorderColor)(props)};
      &:hover {
        border: solid ${themeGet('borders.1')}px
          ${props =>
            themeGet(themeModeProps[props.themeMode].errorBorderColor)(props)};
      }
    }
  }

  .rc-input::placeholder,
  ${StyledPlaceHolder} {
    width: 100%;
    color: ${props =>
      themeGet(
        `colors.text.${props.themeMode}.${props.disabled ? 'text3' : 'text2'}`
      )(props)};
    font-weight: ${themeGet('fontWeights.medium')};
  }

  input {
    border: none;
    background: transparent;
    outline: none;
    padding: 0;
    flex: 1;

    &[type='number'] {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield;
    }

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }

  .rc-input-suffix,
  .rc-input-prefix {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .rc-input-group-wrapper {
    .rc-input-wrapper {
      display: inline-flex;
      width: 100%;

      > span:first-child {
        border-top-left-radius: ${props =>
          themeGet(sizeProps[props.inputSize].borderRadius)(props)}px;
        border-bottom-left-radius: ${props =>
          themeGet(sizeProps[props.inputSize].borderRadius)(props)}px;
      }

      > span:last-child {
        border-top-right-radius: ${props =>
          themeGet(sizeProps[props.inputSize].borderRadius)(props)}px;
        border-bottom-right-radius: ${props =>
          themeGet(sizeProps[props.inputSize].borderRadius)(props)}px;
      }

      .rc-input-affix-wrapper {
        flex: 1;
        border-radius: ${themeGet('radii.0')}px;
      }

      .rc-input-group-addon {
        height: ${props =>
          themeGet(sizeProps[props.inputSize].height)(props)}px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid ${themeGet('borders.1')}px
          ${props =>
            themeGet(themeModeProps[props.themeMode].borderColor)(props)};
        border-left: none;
        box-sizing: border-box;
        padding: 0
          ${props => themeGet(sizeProps[props.inputSize].padding)(props)}px;
        margin-top: ${props =>
          themeGet(
            `space.${props.inputSize !== 'small' && props.label ? 2 : 0}`
          )(props)}px;
      }
    }
  }
`;

export const TextAreaWrapper = styled.textarea<{
  inputSize: InputProps['size'];
  disabled?: boolean;
  hasError?: boolean;
  themeMode: ThemeMode;
}>`
  width: 100%;
  background-color: ${({ themeMode }) =>
    themeGet(`colors.surface.${themeMode}.surface2`)};
  color: ${({ themeMode, disabled }) =>
    themeGet(`colors.text.${themeMode}.${disabled ? 'text3' : 'text1'}`)};
  padding: ${themeGet('space.2')}px ${themeGet('space.3')}px;
  box-sizing: border-box;
  border-radius: ${themeGet('radii.2')}px;
  resize: none;
  border: solid ${themeGet('borders.1')}px
    ${props =>
      themeGet(
        themeModeProps[props.themeMode][
          props.hasError ? 'errorBorderColor' : 'borderColor'
        ]
      )(props)};
  ::placeholder {
    color: ${({ themeMode, disabled }) =>
      themeGet(`colors.text.${themeMode}.${disabled ? 'text3' : 'text2'}`)};
    font-weight: ${themeGet('fontWeights.medium')};
  }
  :focus {
    outline: none !important;
    border: solid ${themeGet('borders.1')}px
      ${props =>
        themeGet(
          themeModeProps[props.themeMode][
            props.hasError ? 'errorBorderColor' : 'focusedBorderColor'
          ]
        )(props)};
  }
  font-size: ${props => themeGet(sizeProps[props.inputSize].fontSize)(props)}px;
  line-height: ${props =>
    themeGet(sizeProps[props.inputSize].lineHeight)(props)}px;
  color: ${props =>
    themeGet(
      `colors.text.${props.themeMode}.${props.disabled ? 'text3' : 'text1'}`
    )(props)};
  padding: ${props => themeGet(sizeProps[props.inputSize].padding)(props)}px;
`;
