import React, { useCallback, useEffect, useState } from 'react';
import RcInput, { InputRef as RcInputRef } from 'rc-input';

import { InputProps, InputRef } from './types';
import { WithV2Theme } from 'theme-v2/provider/withV2Theme';
import { useThemeMode } from 'theme-v2/provider/index';
import FontIcon from '../../atoms/font-icon';
import FlexBox from '../../atoms/flexbox';
import Paragraph from '../../atoms/paragraph';
import { useInput, useOutsideClickListener } from './hook';
import classNames from 'classnames';
import { sizeProps, themeModeProps } from './constant';
import { StyledInputWrapper, StyledLabel } from './styled';
import { CHAR_LIMIT_ERROR_TEXT } from './constant';
import SelectBox from './select-box';
import { TextArea } from './text-area';
import hasValue from 'shared/utils/hasValue';

const DEFAULT_ALLOWED_INPUT_FORMAT = /[^0-9]/g;

const Input = React.forwardRef<InputRef, InputProps>(
  (
    {
      size,
      label,
      helperText,
      characterLimit,
      placeholder,
      rightIcon: _rightIcon,
      leftIcon: _leftIcon,
      errorText,
      hasError,
      loading,
      disabled,
      value,
      onChange,
      onSubmit,
      type,
      prefix,
      suffix,
      transparent,
      isRequired = false,
      maxLength,
      maxLinesToShow = 4,
      onBlurHandler,
      tabIndex,
      onKeyDown,
      notBlurOnSubmit = false,
      allowedInputFormat = DEFAULT_ALLOWED_INPUT_FORMAT,
      hideClear = false
    },
    ref
  ): JSX.Element => {
    const [themeMode] = useThemeMode();
    const { inputValue, setInputValue, clearInputValue } = useInput(value);
    const [focused, setFocused] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const rcInputRef = React.useRef<RcInputRef | null>(null);
    const textAreaInputRef =
      React.useRef() as React.MutableRefObject<HTMLTextAreaElement>;

    React.useImperativeHandle(ref, () => ({
      focusInput: () => {
        rcInputRef?.current?.focus();
        textAreaInputRef?.current?.focus();
      },
      blurInput: () => {
        rcInputRef?.current?.blur();
        textAreaInputRef?.current?.blur();
      },
      value: rcInputRef?.current?.input?.value
        ? rcInputRef?.current?.input?.value
        : textAreaInputRef?.current?.value
    }));

    useEffect(() => {
      if (value !== inputValue) {
        if (type !== 'number') {
          setInputValue(value);
        } else if (!isNaN(Number(value)) || !hasValue(value)) {
          setInputValue(value);
        }
      }
    }, [value]);

    const iconProps = {
      color: disabled ? 'icon3' : themeModeProps[themeMode].iconColor,
      size: sizeProps[size].iconSize
    };

    const clearButton = (
      <FontIcon
        type='icon-crosslined-1'
        {...iconProps}
        color='icon2'
        className={classNames(
          {
            clickable: !disabled
          },
          'show-on-hover show-on-focus'
        )}
        onClick={() => {
          if (!disabled) {
            clearInputValue();
            onChange &&
              onChange({
                target: { value: '' }
              } as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
            onSubmit && onSubmit('');
          }
        }}
      />
    );
    const togglePasswordButton = (
      <FontIcon
        type={isPasswordVisible ? 'icon-eyeopenline' : 'icon-eyecloseline'}
        {...iconProps}
        className={classNames({
          clickable: !disabled
        })}
        onClick={() => {
          if (!disabled) {
            setIsPasswordVisible(!isPasswordVisible);
          }
        }}
      />
    );
    const loader = <FontIcon type='icon-spinnerlined' {...iconProps} spin />;

    const rightIcon = loading ? (
      loader
    ) : type === 'password' ? (
      togglePasswordButton
    ) : String(inputValue).length > 0 &&
      !['select'].includes(type) &&
      !disabled &&
      !hideClear ? (
      clearButton
    ) : _rightIcon ? (
      <FontIcon {...iconProps} {..._rightIcon} ml={2} />
    ) : (
      <></>
    );

    const leftIcon = _leftIcon ? (
      <FontIcon {...iconProps} {..._leftIcon} mr={2} />
    ) : (
      <></>
    );

    const changeHandler = useCallback(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        e.persist?.();
        if (type === 'number') {
          e.target.value = e.target.value
            ?.replace(allowedInputFormat, '')
            .split('.')
            .slice(0, 2)
            .join('.');

          setInputValue(e.target.value);
          onChange && onChange(e);
        } else {
          setInputValue(e.target.value);
          onChange && onChange(e);
        }
      },
      [onChange]
    );

    const submitHandler = () => {
      if (notBlurOnSubmit) {
        return;
      }
      onSubmit && onSubmit(inputValue);
      rcInputRef?.current?.blur();
      textAreaInputRef?.current?.blur();
      setFocused(false);
    };

    const customBlurHandler = () => {
      submitHandler();
    };

    const blurHandler = onBlurHandler || customBlurHandler;
    const characterLimitExceeded =
      characterLimit && inputValue.length > characterLimit;

    const footerText = errorText
      ? errorText
      : characterLimitExceeded
      ? CHAR_LIMIT_ERROR_TEXT
      : helperText;

    const { wrapperRef: inputWrapperRef } = useOutsideClickListener(
      focused ? blurHandler : undefined
    );

    useEffect(() => {
      const addonElements = inputWrapperRef?.current?.querySelectorAll(
        '.rc-input-group-addon'
      );

      addonElements?.forEach(addon => {
        addon?.addEventListener('click', submitHandler);
      });

      return () => {
        addonElements?.forEach(addon => {
          addon?.removeEventListener('click', submitHandler);
        });
      };
    }, [inputWrapperRef, submitHandler]);

    return (
      <StyledInputWrapper
        inputSize={size}
        themeMode={themeMode}
        disabled={disabled}
        inputType={type}
        transparent={transparent}
      >
        {label ? (
          <StyledLabel
            color={disabled ? 'text3' : 'text2'}
            mb={1}
            fontSize={0}
            isRequired={isRequired}
          >
            {label}
          </StyledLabel>
        ) : null}
        {type === 'select' ? (
          <SelectBox
            className={classNames({
              'input-focused': focused,
              'input-error': hasError || characterLimitExceeded,
              'input-disabled': disabled
            })}
            placeholder={placeholder}
            disabled={disabled}
            suffix={rightIcon}
            prefix={leftIcon}
            value={inputValue}
            onChange={changeHandler}
            onFocus={() => setFocused(true)}
            onBlur={() => {
              setFocused(false);
            }}
            tabIndex={tabIndex}
            {...(prefix ? { addonBefore: prefix } : {})}
            {...(suffix ? { addonAfter: suffix } : {})}
          />
        ) : type === 'textarea' ? (
          <TextArea
            ref={textAreaInputRef}
            className={classNames({
              'input-focused': focused,
              'input-error': hasError || characterLimitExceeded,
              'input-disabled': disabled
            })}
            inputSize={size}
            disabled={disabled}
            hasError={hasError || !!characterLimitExceeded}
            placeholder={placeholder}
            value={inputValue}
            onChange={!disabled ? changeHandler : undefined}
            onFocus={() => setFocused(true)}
            onBlur={() => {
              setFocused(false);
            }}
            tabIndex={tabIndex}
            maxLinesToShow={maxLinesToShow}
          />
        ) : (
          <FlexBox ref={inputWrapperRef} flexDirection='column'>
            <RcInput
              ref={rcInputRef}
              className={classNames({
                'input-focused': focused,
                'input-error': hasError || characterLimitExceeded,
                'input-disabled': disabled
              })}
              type={
                type === 'search' ||
                type === 'number' ||
                (type === 'password' && isPasswordVisible)
                  ? 'text'
                  : type
              }
              placeholder={placeholder}
              disabled={disabled}
              suffix={rightIcon}
              prefix={leftIcon}
              value={inputValue}
              tabIndex={tabIndex}
              onChange={changeHandler}
              onPressEnter={submitHandler}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              onKeyDown={onKeyDown}
              {...(prefix ? { addonBefore: prefix } : {})}
              {...(suffix ? { addonAfter: suffix } : {})}
              {...(maxLength ? { maxLength: maxLength } : {})}
            />
          </FlexBox>
        )}
        {footerText || characterLimit ? (
          <FlexBox mt={2} justifyContent='space-between'>
            {footerText ? (
              <Paragraph
                fontSize={0}
                fontWeight='medium'
                color={
                  hasError || characterLimitExceeded
                    ? 'error'
                    : disabled
                    ? 'text3'
                    : 'text2'
                }
              >
                {footerText}
              </Paragraph>
            ) : null}
            {characterLimit ? (
              <Paragraph
                fontSize={0}
                fontWeight='medium'
                color={disabled ? 'text3' : 'text2'}
                ml={2}
              >
                {inputValue.length}/{characterLimit}
              </Paragraph>
            ) : null}
          </FlexBox>
        ) : null}
      </StyledInputWrapper>
    );
  }
);

export default WithV2Theme<InputProps, InputRef>(Input);
