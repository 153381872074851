import { getServer } from '../../services/server';
import APP_ENV from '../build-env';
import { APP_NAME } from '../build-env';

const SERVER = getServer();
// If you want to change default API endpoints for prod/staging/test,
// update these constants during dev/testing phases. But should be
// reverted before merging into master
const STAGING_URL = `https://app.nvst-staging.com/`;
const PL_URL = `https://app.nvst-staging.com/`;
export const PRODUCTION_URL = `https://app.synaptic.com/`;
const TESTING_URL = `https://app.nvst-staging.com/`;
const DEVELOPMENT_URL = `https://app.nvst-staging.com/`;

// Need to remove this once we have a proper admin url
const STAGING_PORTFOLIO_IQ_URL = `https://admin-api.stage.portfolioiq.co/`;
const PRODUCTION_PORTFOLIO_IQ_URL = `https://admin-api.portfolioiq.co/`;
const TESTING_PORTFOLIO_IQ_URL = `https://admin-api.dev.portfolioiq.co/`;
const DEVELOPMENT_PORTFOLIO_IQ_URL = `https://admin-api.stage.portfolioiq.co/`;

let STAGING_PORTFOLIO_CF_IQ_URL = `https://app.stage.portfolioiq.co/`;
let PRODUCTION_PORTFOLIO_CF_IQ_URL = `https://app.portfolioiq.co/`;
let TESTING_PORTFOLIO_CF_IQ_URL = `https://app.dev.portfolioiq.co/`;
let DEVELOPMENT_PORTFOLIO_CF_IQ_URL = `https://app.stage.portfolioiq.co/`;

if (APP_NAME === 'forge') {
  STAGING_PORTFOLIO_CF_IQ_URL = `https://forge.stage.portfolioiq.co/`;
  PRODUCTION_PORTFOLIO_CF_IQ_URL = `https://forge.synaptic.com/`;
  TESTING_PORTFOLIO_CF_IQ_URL = `https://forge.dev.portfolioiq.co/`;
  DEVELOPMENT_PORTFOLIO_CF_IQ_URL = `https://forge.stage.portfolioiq.co/`;
}

// DEVELOPMENT_PORTFOLIO_CF_IQ_URL = `https://app.dev.portfolioiq.co/`;
// DEVELOPMENT_PORTFOLIO_CF_IQ_URL = `https://forge.dev.portfolioiq.co/`;

export const API_VERSION = 'api/v4/';
export const API_VERSION_V5 = 'api/v5/';
export const API_VERSION_V6 = 'api/v6/';
export const API_VERSION_V7 = 'api/v7/';

export const JSON_CONTENT_TYPE = 'application/json';
export const EXCLUDED_AUTH_REDIRECTIONS = [
  'sso_authorizations/callback',
  'auth/authorizations/auth_url',
  'application_versions',
  'users/event_track'
];
export const AUTH_ENDPOINTS = ['api/v5/auth'];

let _API_URL = null;
let _PORTFOLIO_IQ_API_URL = null;
let _PORTFOLIO_IQ_CF_API_URL = null;

if (APP_ENV === 'production') {
  _API_URL = PRODUCTION_URL;
  _PORTFOLIO_IQ_API_URL = PRODUCTION_PORTFOLIO_IQ_URL;
  _PORTFOLIO_IQ_CF_API_URL = PRODUCTION_PORTFOLIO_CF_IQ_URL;
} else if (APP_ENV === 'staging') {
  _API_URL = STAGING_URL;
  _PORTFOLIO_IQ_API_URL = STAGING_PORTFOLIO_IQ_URL;
  _PORTFOLIO_IQ_CF_API_URL = STAGING_PORTFOLIO_CF_IQ_URL;
} else if (APP_ENV === 'test') {
  _API_URL = TESTING_URL;
  _PORTFOLIO_IQ_API_URL = TESTING_PORTFOLIO_IQ_URL;
  _PORTFOLIO_IQ_CF_API_URL = TESTING_PORTFOLIO_CF_IQ_URL;
} else if (APP_ENV === 'design_staging') {
  _API_URL = PL_URL;
  _PORTFOLIO_IQ_API_URL = STAGING_PORTFOLIO_IQ_URL;
  _PORTFOLIO_IQ_CF_API_URL = STAGING_PORTFOLIO_CF_IQ_URL;
} else {
  _API_URL = DEVELOPMENT_URL;
  _PORTFOLIO_IQ_API_URL = DEVELOPMENT_PORTFOLIO_IQ_URL;
  _PORTFOLIO_IQ_CF_API_URL = DEVELOPMENT_PORTFOLIO_CF_IQ_URL;
}

if (SERVER) {
  _API_URL = SERVER;
}

if (SERVER && APP_ENV !== 'production') {
  _PORTFOLIO_IQ_API_URL = SERVER;
  _PORTFOLIO_IQ_CF_API_URL = SERVER;
}

export const API_URL = _API_URL;
export const ADMIN_API_URL = _API_URL;
export const PORTFOLIO_IQ_API_URL = _PORTFOLIO_IQ_API_URL;
export const PORTFOLIO_IQ_CF_API_URL = _PORTFOLIO_IQ_CF_API_URL;

export const ADMIN_PREFIX = 'admin/';
export const ADMIN_API_VERSION_V1 = 'api/v1/';
export const ADMIN_API_VERSION_V2 = 'api/v2/';
export const ADMIN_API_VERSION_V3 = 'api/v3/';

export const ASSOCIATE_PREFIX = 'associate/';
export const ASSOCIATE_API_VERSION_V1 = 'api/v1/';

export const PORTFOLIO_API_VERSION_V1 =
  APP_NAME === 'forge' || APP_NAME === 'piq-lp' ? 'forge/api/v1/' : 'api/v1/';
export const PORTFOLIO_API_VERSION_V2 =
  APP_NAME === 'forge' || APP_NAME === 'piq-lp' ? 'forge/api/v2/' : 'api/v2/';
