import DefaultTheme from '../themes/default';
import Forge from '../themes/forge';
import LP from '../themes/lp';
import { type Theme } from '../types';
import { type ThemeMode } from './mode';

export const ThemeMap = {
  default: DefaultTheme,
  forge: Forge,
  lp: LP
};

export const createTheme = (
  themeName: keyof typeof ThemeMap,
  themeMode?: ThemeMode
): Theme => {
  const theme: Theme = ThemeMap[themeName] ?? DefaultTheme;
  theme.themeMode = themeMode ?? 'light';
  return theme;
};
